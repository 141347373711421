import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { refreshTokens, signIn } from "../thunks/auth";
import { IAuthResponce } from "../types";

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
}

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        signIn.fulfilled,
        (
          state,
          action: PayloadAction<{ accessToken: string; refreshToken: string }>
        ) => {
          state.accessToken = action.payload.accessToken;
          state.refreshToken = action.payload.refreshToken;
        }
      )
      .addCase(
        refreshTokens.fulfilled,
        (state, action: PayloadAction<IAuthResponce>) => {
          state.accessToken = action.payload.accessToken;
          state.refreshToken = action.payload.refreshToken;
        }
      );
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
