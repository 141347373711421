import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBook } from "../../types/books";
import { fetchBooks } from "../thunks/books";

interface BooksState {
  books: IBook[];
  searchResults: IBook[];
}

const initialState: BooksState = {
  books: [],
  searchResults: [],
};

const booksSlice = createSlice({
  name: "books",
  initialState,
  reducers: {
    searchBooks: (state, action: PayloadAction<string>) => {
      const query = action.payload.toLowerCase();

      state.searchResults = state.books.filter((book) => {
        const hasTitle = book.title && typeof book.title === "string";
        const hasAuthor = book.author && typeof book.author === "string";

        if (hasTitle && book.title.toLowerCase().includes(query)) {
          return true;
        }

        if (hasAuthor && book.author.toLowerCase().includes(query)) {
          return true;
        }

        return false;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchBooks.fulfilled,
      (state, action: PayloadAction<IBook[]>) => {
        state.books = action.payload;
        state.searchResults = action.payload;
      }
    );
  },
});

export const { searchBooks } = booksSlice.actions;
export default booksSlice.reducer;
