import { createAsyncThunk } from "@reduxjs/toolkit";

import { setError, setLoading } from "../slices/app";
import { ICategory } from "../../types/categories";

import axiosInstance from "../instance/axiosInstance";
import { RootState } from "../store";

export const fetchCategories = createAsyncThunk<
  ICategory[],
  undefined,
  { state: RootState }
>("categories/fetchCategories", async (_, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await axiosInstance.get<ICategory[]>(
      `${process.env.REACT_APP_API_URL}/categories`
    );

    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message;

    dispatch(setError(errorMessage));
    return rejectWithValue(errorMessage);
  } finally {
    dispatch(setLoading(false));
  }
});

export const addCategory = createAsyncThunk<
  ICategory,
  string,
  { state: RootState }
>(
  "categories/addCategory",
  async (newCategory, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/categories`,
        { name: newCategory }
      );

      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;

      dispatch(setError(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const editCategory = createAsyncThunk<
  ICategory,
  ICategory,
  { state: RootState }
>(
  "categories/editCategory",
  async (updatedCategory, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.patch<ICategory>(
        `${process.env.REACT_APP_API_URL}/categories/${updatedCategory._id}`,
        updatedCategory
      );

      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;

      dispatch(setError(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const deleteCategory = createAsyncThunk<
  string,
  string,
  { state: RootState }
>(
  "categories/deleteCategory",
  async (categoryId, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      await axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}/categories/${categoryId}`
      );

      return categoryId;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;

      dispatch(setError(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
