import React from "react";
import { ControllerRenderProps } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import { useAppSelector } from "../../hooks/redux";
import { categoriesSelector } from "../../store/selectors/categories";

import styles from "./CategorySelector.module.css";

interface CategorySelectorProps {
  field: ControllerRenderProps<any, any>;
  error?: boolean;
  helperText?: string;
}

const CategorySelector: React.FC<CategorySelectorProps> = ({
  field,
  error,
  helperText,
}) => {
  const categories = useAppSelector(categoriesSelector);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    field.onChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl fullWidth error={error} className={styles.container}>
      <Select
        multiple
        value={field.value || []}
        onChange={handleChange}
        renderValue={(selected) =>
          (selected as string[])
            .map(
              (id) => categories.find((category) => category._id === id)?.name
            )
            .join(", ")
        }
        displayEmpty
        className={styles.select__control}
        sx={{ borderRadius: "40px" }}
      >
        {categories.map((category) => (
          <MenuItem key={category._id} value={category._id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CategorySelector;
