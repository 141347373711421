import { FC, ReactNode } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MainButton from "../../buttons/MainButton/MainButton";

interface ResultModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  onConfirm?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
}

const ResultModal: FC<ResultModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  onConfirm,
  confirmLabel = "Confirm",
  cancelLabel = "Cancel",
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "40px",
          padding: "1rem",
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <MainButton label={cancelLabel} onClick={onClose} />
        {onConfirm && (
          <MainButton
            label={confirmLabel}
            warning={confirmLabel === "Delete"}
            onClick={onConfirm}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ResultModal;
