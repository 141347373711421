import { FC, ReactNode } from "react";
import styles from "./MiniButton.module.css";

interface MiniButtonProps {
  children: ReactNode;
  onClick?: () => void;
  type?: "button" | "submit";
  secondary?: boolean;
}

const MiniButton: FC<MiniButtonProps> = ({
  children,
  onClick,
  type = "button",
  secondary,
}) => {
  return (
    <button
      onClick={onClick}
      className={secondary ? styles.buttonSecondary : styles.button}
      type={type}
    >
      {children}
    </button>
  );
};

export default MiniButton;
