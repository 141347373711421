import React, { useEffect, useState } from "react";

import CategoryCard from "../../components/CategoryCard/CategoryCard";
import InputField from "../../components/InputField/InputField";
import AddCategoryModal from "../../components/modals/AddCategoryModal/AddCategoryModal";
import MainButton from "../../components/buttons/MainButton/MainButton";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { searchCategoriesResultSelector } from "../../store/selectors/categories";
import { searchCategories } from "../../store/slices/categories";
import {
  addCategory,
  deleteCategory,
  editCategory,
  fetchCategories,
} from "../../store/thunks/categories";

import styles from "./CategoriesPage.module.css";

const CategoriesPage = () => {
  const dispatch = useAppDispatch();
  const categoriesList = useAppSelector(searchCategoriesResultSelector);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const existingCategoryNames = categoriesList.map((category) => category.name);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    dispatch(searchCategories(value));
  };

  const handleEdit = (_id: string, name: string) => {
    dispatch(editCategory({ _id, name }));
  };

  const handleDelete = (id: string) => {
    dispatch(deleteCategory(id));
  };

  const handleAddCategory = (name: string) => {
    dispatch(addCategory(name));
  };

  return (
    <div className={styles.categoriesContainer}>
      <div className={styles.searchContainer}>
        <InputField type="text" placeholder="Search" onChange={handleSearch} />
      </div>
      <div className={styles.headerContainer}>
        <div></div>
        <h1 className={styles.header}>Categories:</h1>
        <MainButton
          label="Add Category"
          onClick={() => setIsAddModalOpen(true)}
        />
      </div>
      <div className={styles.categoriesGrid}>
        {categoriesList.length ? (
          categoriesList.map((category) => (
            <CategoryCard
              key={category._id}
              {...category}
              onEdit={handleEdit}
              onDelete={handleDelete}
              existingCategoryNames={existingCategoryNames}
            />
          ))
        ) : (
          <h3>There aren't any categories here.</h3>
        )}
      </div>
      <AddCategoryModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAddCategory={handleAddCategory}
        existingCategoryNames={existingCategoryNames}
      />
    </div>
  );
};

export default CategoriesPage;
