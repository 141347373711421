import { createAsyncThunk } from "@reduxjs/toolkit";

import { IBook } from "../../types/books";
import { RootState } from "../store";

import axiosInstance from "../instance/axiosInstance";
import { setResult, setError, setLoading } from "../slices/app";
import { mapBookData } from "../../helpers/createBookData";
import { UploadBookPayload } from "../types";

const booksResult = {
  success: {
    title: "Success",
    content: `Book successfully uploaded`,
  },
  edit: {
    title: "Edit",
    content: `Book successfully edited`,
  },
  delete: {
    title: "Delete",
    content: `Book successfully deleted`,
  },
};

export const fetchBooks = createAsyncThunk<
  IBook[],
  undefined,
  { state: RootState }
>("books/fetchBooks", async (_, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await axiosInstance.get<IBook[]>("/books");

    return response.data.map(mapBookData);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message;

    dispatch(setError(errorMessage));
    return rejectWithValue(errorMessage);
  } finally {
    dispatch(setLoading(false));
  }
});

export const uploadBook = createAsyncThunk<
  IBook,
  UploadBookPayload,
  { state: RootState }
>(
  "books/uploadBook",
  async ({ data, files }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));

      const formData = new FormData();

      formData.append("title", data.title);
      formData.append("author", data.author);
      formData.append("minAge", data.minAge.toString());
      formData.append("price", data.price.toString());

      if (files.book) {
        formData.append("book", files.book);
      }
      if (files.cover) {
        formData.append("cover", files.cover);
      }

      if (data.categories) {
        formData.append("categories", data.categories.join(","));
      }

      const responce = await axiosInstance.post<IBook>("/books", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      dispatch(setResult(booksResult.success));

      return responce.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;

      dispatch(setError(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const editBook = createAsyncThunk<
  IBook,
  UploadBookPayload,
  { state: RootState }
>("books/editBook", async (updatedBook, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  const formData = new FormData();

  formData.append("title", updatedBook.data.title);
  formData.append("minAge", updatedBook.data.minAge.toString());
  formData.append("price", updatedBook.data.price.toString());

  if (updatedBook.data.categories) {
    formData.append("categories", updatedBook.data.categories.join(","));
  }

  if (updatedBook.files.cover) {
    formData.append("cover", updatedBook.files.cover);
  }

  if (updatedBook.files.book) {
    formData.append("book", updatedBook.files.book);
  }

  try {
    const responce = await axiosInstance.patch<IBook>(
      `/books/${updatedBook.data._id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    dispatch(setResult(booksResult.edit));
    dispatch(fetchBooks());

    return responce.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message;

    dispatch(setError(errorMessage));
    return rejectWithValue(errorMessage);
  } finally {
    dispatch(setLoading(false));
  }
});

export const deleteBook = createAsyncThunk<
  string,
  string,
  { state: RootState }
>("books/deleteBook", async (bookId, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    await axiosInstance.delete(`/books/${bookId}`);

    dispatch(setResult(booksResult.delete));
    dispatch(fetchBooks());

    return bookId;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message;

    dispatch(setError(errorMessage));
    return rejectWithValue(errorMessage);
  } finally {
    dispatch(setLoading(false));
  }
});
