import axios from "axios";
import { unwrapResult } from "@reduxjs/toolkit";

import { refreshTokens } from "../thunks/auth";
import { logout } from "../slices/auth";
import store from "../store";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const accessToken = state.auth.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const state = store.getState();
        const refreshToken = state.auth.refreshToken;
        console.log("🚀 ~ refreshToken:", refreshToken);

        if (refreshToken) {
          const resultAction = await store.dispatch(
            refreshTokens({ refreshToken })
          );
          const result = unwrapResult(resultAction);

          const { accessToken } = result;
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${accessToken}`;
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;

          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        if (
          refreshError &&
          typeof refreshError === "object" &&
          "response" in refreshError
        ) {
          const response = (refreshError as any).response;
          if (response && response?.status === 401) {
            store.dispatch(logout());
          }
        }
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
