import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import styles from "./MenuButton.module.css";

interface MenuButtonProps {
  name: string;
  path: string;
  icon: React.ReactNode;
  onClick: () => void;
}

const MenuButton: FC<MenuButtonProps> = ({ name, path, icon, onClick }) => {
  return (
    <NavLink to={path} className={styles.menuButton} onClick={onClick}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.name}>{name}</div>
    </NavLink>
  );
};

export default MenuButton;
