import { FC } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import MainButton from "../../buttons/MainButton/MainButton";

interface ErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  errorMessage: string;
  closeLabel?: string;
}

const ErrorModal: FC<ErrorModalProps> = ({
  isOpen,
  onClose,
  title,
  errorMessage,
  closeLabel = "Close",
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "40px",
          backgroundColor: "#fff", // light red background to indicate error
          color: "#b71c1c", // dark red text color
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div>{errorMessage}</div>
      </DialogContent>
      <DialogActions>
        <MainButton label={closeLabel} onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

export default ErrorModal;
