import { FC } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc =
  window.location.origin + "/pdf.worker.min.mjs";

interface PdfPreviewProps {
  file: File;
}

const PdfPreview: FC<PdfPreviewProps> = ({ file }) => {
  return (
    <div className="pdfPreview">
      <Document file={file}>
        <Page pageNumber={1} />
      </Document>
    </div>
  );
};

export default PdfPreview;
