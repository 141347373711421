import { FC, ButtonHTMLAttributes } from "react";
import styles from "./MainButton.module.css";

interface MainButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  warning?: boolean;
}

const MainButton: FC<MainButtonProps> = ({ label, warning, ...props }) => {
  return (
    <button
      className={warning ? `${styles.warn} ${styles.button}` : styles.button}
      {...props}
    >
      {label}
    </button>
  );
};

export default MainButton;
