import { FC } from "react";
import { Navigate, RouteProps } from "react-router-dom";

import { useAppSelector } from "../../hooks/redux";
import { authStateSelector } from "../../store/selectors/auth";

const PrivateRoute: FC<RouteProps> = ({ children }) => {
  const { accessToken } = useAppSelector(authStateSelector);

  if (!accessToken) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
