import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../instance/axiosInstance";

import { setError, setLoading } from "../slices/app";
import { RootState } from "../store";
import { IAuthResponce, RefreshTokenDto, SignInDto } from "../types";

export const signIn = createAsyncThunk<
  IAuthResponce,
  SignInDto,
  { state: RootState }
>(
  "auth/signIn",
  async (signInData: SignInDto, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.post("/authentication/sign-in", {
        email: signInData.username,
        password: signInData.password,
      });

      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;

      dispatch(setError(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const refreshTokens = createAsyncThunk<
  IAuthResponce,
  RefreshTokenDto,
  { state: RootState }
>(
  "auth/refreshTokens",
  async (refreshTokenData, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.post(
        "/authentication/refresh-tokens",
        refreshTokenData
      );

      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;

      dispatch(setError(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
