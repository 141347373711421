import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./slices/auth";
import booksReducer from "./slices/books";
import categoriesReducer from "./slices/categories";
import appReducer from "./slices/app";

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  books: booksReducer,
  categories: categoriesReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "categories", "books"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
