import { IBook } from "../types/books";
import { ICategory } from "../types/categories";

export const mapBookData = (book: any): IBook => ({
  _id: book._id,
  title: book.title,
  author: book.author,
  price: book.price,
  pdfUrl: `${process.env.REACT_APP_GC_URI}/${book.bookURI}`,
  imageUrl: `${process.env.REACT_APP_GC_URI}/${book.coverURI}`,
  minAge: book.minAge,
  categories: book.categories.map((category: ICategory) => category._id),
});
