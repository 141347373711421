import { FC, useState, ChangeEvent } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import InputField from "../../components/InputField/InputField";
import MainButton from "../../components/buttons/MainButton/MainButton";
import ResultModal from "../../components/modals/ResultModal/ResultModal";
import CategorySelector from "../../components/CategorySelector/CategorySelector";
import ImageUploader from "../../components/ImageUploader/ImageUploader";
import PdfPreview from "../../components/PdfPreview/PdfPreview";

import { RootState } from "../../store/store";
import { IBook } from "../../types/books";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { deleteBook, editBook } from "../../store/thunks/books";
import { categoriesSelector } from "../../store/selectors/categories";
import { bookByIdSelector } from "../../store/selectors/books";
import { UploadBookPayload } from "../../store/types";

import styles from "./BookPage.module.css";

const BookPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const book = useAppSelector((state: RootState) =>
    bookByIdSelector(state, id)
  );
  console.log("🚀 ~ book:", book);

  const categories = useAppSelector(categoriesSelector);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IBook>({
    defaultValues: {
      title: book?.title || "",
      author: book?.author || "",
      price: book?.price || 0,
      minAge: book?.minAge || 0,
      categories: book?.categories || [],
      imageUrl: book?.imageUrl || "",
      pdfUrl: book?.pdfUrl || "",
    },
  });

  const [editMode, setEditMode] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [pdf, setPdf] = useState<File | null>(null);
  const [pdfName, setPdfName] = useState<string | null>(
    book?.pdfUrl ? "Book Uploaded" : null
  );
  const [modalOpen, setModalOpen] = useState(false);

  if (!book) {
    return (
      <div className={styles.bookDetails}>
        <p className={styles.bookNotFound}>Book not found</p>
      </div>
    );
  }

  const handlePdfChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setPdf(e.target.files[0]);
      setPdfName(e.target.files[0].name);
    }
  };

  const onSubmit = (data: IBook) => {
    const updatedBook: UploadBookPayload = {
      data: {
        ...data,
        _id: book._id,
        pdfUrl: pdf ? URL.createObjectURL(pdf) : data.pdfUrl,
        imageUrl: image ? URL.createObjectURL(image) : book.imageUrl,
      },
      files: {
        cover: image,
        book: pdf,
      },
    };

    dispatch(editBook(updatedBook));
  };

  const handleDelete = () => {
    if (book._id) {
      dispatch(deleteBook(book._id)).then(() => {
        navigate("/dashboard/books");
      });
    }
  };

  return (
    <div className={styles.bookDetails}>
      <ImageUploader
        image={image}
        imageUrl={book.imageUrl}
        onImageChange={setImage}
        editMode={editMode}
      />
      {editMode ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.inputsContainer}
        >
          <div className={styles.inputGroup}>
            <label>Title</label>
            <Controller
              name="title"
              control={control}
              rules={{ required: "Title is required" }}
              render={({ field }) => (
                <InputField
                  type="text"
                  placeholder="Title"
                  {...field}
                  error={errors.title ? errors.title.message : ""}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Author</label>
            <Controller
              name="author"
              control={control}
              rules={{ required: "Author is required" }}
              render={({ field }) => (
                <InputField
                  type="text"
                  placeholder="Author"
                  {...field}
                  error={errors.author ? errors.author.message : ""}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Minimal age</label>
            <Controller
              name="minAge"
              control={control}
              rules={{ required: "Minimal age is required" }}
              render={({ field }) => (
                <InputField
                  type="number"
                  placeholder="Minimal age"
                  {...field}
                  error={errors.minAge?.message}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Price</label>
            <Controller
              name="price"
              control={control}
              rules={{ required: "Price is required" }}
              render={({ field }) => (
                <InputField
                  type="number"
                  placeholder="Price"
                  {...field}
                  error={errors.price ? errors.price.message : ""}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Categories</label>
            <Controller
              name="categories"
              control={control}
              rules={{ required: "At least one category is required" }}
              render={({ field, fieldState }) => (
                <CategorySelector
                  field={field}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className={`${styles.inputGroup} ${styles.bookItem}`}>
            <label>{`Book (PDF)${pdfName ? ` - ${pdfName}` : ""}`}</label>
            <input
              type="file"
              id="pdf-upload"
              onChange={handlePdfChange}
              accept="application/pdf"
              style={{ display: "none" }}
            />
            <div>
              <MainButton
                label="Upload PDF"
                onClick={(event) => {
                  event.preventDefault();
                  document.getElementById("pdf-upload")?.click();
                }}
              />
            </div>
          </div>
          {pdf && <PdfPreview file={pdf} />}
          <MainButton label="Save" type="submit" />
        </form>
      ) : (
        <div className={styles.mainContainer}>
          <h1>{book.title}</h1>
          <p>by: {book.author}</p>
          <p>Minimal age: {book.minAge}</p>
          <p>Price: {book.price}</p>
          {book.pdfUrl && (
            <p>
              <a href={book.pdfUrl} target="_blank" rel="noopener noreferrer">
                Download Book
              </a>
            </p>
          )}
          <p>
            Categories:{" "}
            {book.categories
              .map(
                (id) => categories.find((category) => category._id === id)?.name
              )
              .join(", ")}
          </p>
          <div className={styles.buttonsContainer}>
            <MainButton label="Edit" onClick={() => setEditMode(true)} />
            <MainButton
              label="Delete"
              warning
              onClick={() => setModalOpen(true)}
            />
          </div>
        </div>
      )}
      <ResultModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Delete Book"
        confirmLabel="Delete"
        onConfirm={handleDelete}
      >
        <p>Do you really want to delete this book?</p>
      </ResultModal>
    </div>
  );
};

export default BookPage;
