import React, { forwardRef } from "react";

import styles from "./InputField.module.css";

interface InputFieldProps {
  type: React.HTMLInputTypeAttribute;
  placeholder: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  error?: string;
  textarea?: boolean;
  value?: string | number;
}

const InputField = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  InputFieldProps
>(({ type, placeholder, onChange, error, textarea = false, value }, ref) => {
  return (
    <div className={styles.container}>
      {textarea ? (
        <textarea
          className={styles.input}
          placeholder={placeholder}
          ref={ref as React.Ref<HTMLTextAreaElement>}
          onChange={onChange}
          value={value}
        />
      ) : (
        <input
          className={styles.input}
          type={type}
          placeholder={placeholder}
          ref={ref as React.Ref<HTMLInputElement>}
          onChange={onChange}
          value={value}
        />
      )}
      <div className={styles.errorContainer}>
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </div>
  );
});

InputField.displayName = "InputField";

export default InputField;
