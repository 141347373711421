import { FC } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import InputField from "../../InputField/InputField";

interface AddCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddCategory: (name: string) => void;
  existingCategoryNames: string[];
}

const AddCategoryModal: FC<AddCategoryModalProps> = ({
  isOpen,
  onClose,
  onAddCategory,
  existingCategoryNames,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const validateUniqueName = (value: string) => {
    if (existingCategoryNames.includes(value)) {
      return "Category name already exists";
    }
    return true;
  };

  const handleAddCategory = (data: { name: string }) => {
    onAddCategory(data.name);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "40px",
          width: "50%",
        },
      }}
    >
      <DialogTitle>Add New Category</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleAddCategory)}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Category name is required",
              validate: validateUniqueName,
            }}
            render={({ field }) => (
              <InputField
                type="text"
                placeholder="Category Name"
                error={errors.name?.message}
                {...field}
              />
            )}
          />
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddCategoryModal;
