import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICategory } from "../../types/categories";
import {
  addCategory,
  deleteCategory,
  editCategory,
  fetchCategories,
} from "../thunks/categories";

interface CategoriesState {
  categories: ICategory[];
  searchResults: ICategory[];
}

const initialState: CategoriesState = {
  categories: [],
  searchResults: [],
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    searchCategories: (state, action: PayloadAction<string>) => {
      const query = action.payload.toLowerCase();
      state.searchResults = state.categories.filter((category) =>
        category.name.toLowerCase().includes(query)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCategories.fulfilled,
        (state, action: PayloadAction<ICategory[]>) => {
          state.categories = action.payload;
          state.searchResults = action.payload;
        }
      )
      .addCase(
        addCategory.fulfilled,
        (state, action: PayloadAction<ICategory>) => {
          state.categories.push(action.payload);
          state.searchResults.push(action.payload);
        }
      )
      .addCase(
        editCategory.fulfilled,
        (state, action: PayloadAction<ICategory>) => {
          const index = state.categories.findIndex(
            (category) => category._id === action.payload._id
          );
          if (index !== -1) {
            state.categories[index] = action.payload;
            const searchIndex = state.searchResults.findIndex(
              (category) => category._id === action.payload._id
            );
            if (searchIndex !== -1) {
              state.searchResults[searchIndex] = action.payload;
            }
          }
        }
      )
      .addCase(
        deleteCategory.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.categories = state.categories.filter(
            (category) => category._id !== action.payload
          );
          state.searchResults = state.searchResults.filter(
            (category) => category._id !== action.payload
          );
        }
      );
  },
});

export const { searchCategories } = categoriesSlice.actions;
export default categoriesSlice.reducer;
