import { FC } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import InputField from "../InputField/InputField";
import MainButton from "../buttons/MainButton/MainButton";

import { signIn } from "../../store/thunks/auth";
import { useAppDispatch } from "../../hooks/redux";

import styles from "./LoginForm.module.css";

interface LoginFormInputs {
  login: string;
  password: string;
}

const LoginForm: FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    defaultValues: {
      login: "",
      password: "",
    },
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<LoginFormInputs> = (data) => {
    dispatch(signIn({ username: data.login, password: data.password })).then(
      (action: any) => {
        if (action.type === "auth/signIn/fulfilled") {
          navigate("/dashboard");
        }
      }
    );
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="login"
        control={control}
        rules={{
          required: "Login is required",
          pattern: {
            value: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
            message: "Invalid email address",
          },
        }}
        render={({ field }) => (
          <InputField
            type="text"
            placeholder="Login"
            {...field}
            error={errors.login?.message}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        rules={{
          required: "Password is required",
          minLength: {
            value: 6,
            message: "Password must be at least 6 characters",
          },
        }}
        render={({ field }) => (
          <InputField
            type="password"
            placeholder="Password"
            {...field}
            error={errors.password?.message}
          />
        )}
      />
      <MainButton label="Log in" />
    </form>
  );
};

export default LoginForm;
