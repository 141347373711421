import { FC, useEffect, useState } from "react";

import LoginForm from "../../components/LoginForm/LoginForm";
import Loader from "../../components/Loader/Loader";
import ErrorModal from "../../components/modals/ErrorModal/ErrorModal";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { appStateSelector } from "../../store/selectors/app";
import { setError } from "../../store/slices/app";

import styles from "./LoginPage.module.css";

const LoginPage: FC = () => {
  const dispatch = useAppDispatch();
  const { loading, error } = useAppSelector(appStateSelector);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsErrorModalOpen(!!error);
  }, [error]);

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    dispatch(setError(null));
  };

  return (
    <main className={styles.main}>
      <div className={styles.formContainer}>
        {loading && <Loader />}
        <ErrorModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
          title="Error"
          errorMessage={error || ""}
          closeLabel="Close"
        />
        <LoginForm />
      </div>
    </main>
  );
};

export default LoginPage;
