import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IResult } from "../types";

interface AppState {
  loading: boolean;
  result: IResult | null;
  error: string | null;
}

const initialState: AppState = {
  loading: false,
  result: null,
  error: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setResult: (state, action: PayloadAction<IResult | null>) => {
      state.result = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const { setLoading, setError, setResult } = appSlice.actions;
export default appSlice.reducer;
