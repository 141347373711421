import { FC, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import SideMenu from "../SideMenu/SideMenu";
import Loader from "../Loader/Loader";
import ErrorModal from "../modals/ErrorModal/ErrorModal";
import ResultModal from "../modals/ResultModal/ResultModal";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setResult, setError } from "../../store/slices/app";
import { appStateSelector } from "../../store/selectors/app";

import styles from "./DashboardLayout.module.css";

const DashboardLayout: FC = () => {
  const dispatch = useAppDispatch();

  const { loading, error, result } = useAppSelector(appStateSelector);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [isResultModalOpen, setIsResultModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsErrorModalOpen(!!error);
  }, [error]);

  useEffect(() => {
    setIsResultModalOpen(!!result);
  }, [result]);

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    dispatch(setError(null));
  };

  const handleCloseResultModal = () => {
    setIsResultModalOpen(false);
    dispatch(setResult(null));
  };

  return (
    <div className={styles.dashboard}>
      <SideMenu />
      <div className={styles.mainContent}>
        <ErrorModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
          title="Error"
          errorMessage={error || ""}
          closeLabel="Close"
        />
        {result?.title && result.content && (
          <ResultModal
            isOpen={isResultModalOpen}
            title={result?.title}
            children={result?.content}
            cancelLabel="Ok"
            onClose={handleCloseResultModal}
          />
        )}
        {loading && <Loader />}
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;
